import React from 'react'

export const BoommedSuiteSmallLogoColor = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <g clipPath="url(#a)">
      <rect width={32} height={32} fill="#000" rx={6} />
      <g filter="url(#b)">
        <path
          fill="url(#c)"
          d="M16.432 24.105A8.923 8.923 0 0 1 25 17.685V11.39c-7.537 0-13.81 5.515-15.01 12.715h6.442Z"
        />
        <path
          fill="url(#d)"
          d="M15.568 8c-1.094 3.705-4.505 6.421-8.568 6.421v6.295c7.537 0 13.81-5.516 15.01-12.716h-6.442Z"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="c"
        x1={9.987}
        x2={24.992}
        y1={17.754}
        y2={17.754}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22A1A8" />
        <stop offset={1} stopColor="#60BF8F" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={7}
        x2={22.004}
        y1={14.362}
        y2={14.362}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#22A1A8" />
        <stop offset={1} stopColor="#60BF8F" />
      </linearGradient>
      <clipPath id="a">
        <rect width={32} height={32} fill="#fff" rx={6} />
      </clipPath>
      <filter
        id="b"
        width={26}
        height={24.105}
        x={3}
        y={8}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_481_1202"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_481_1202"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
