import { CustomPalette, theme } from '../../app/Theme'

export const styles = {
  root: {
    background: CustomPalette.master.darkGrey,
    height: '64px',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: CustomPalette.master.white,
  },
}
