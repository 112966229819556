import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import PeopleIcon from '@mui/icons-material/People'
import {
  Fab,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'
import { AppRoutes, useAppNavigate } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { CustomPalette } from '../../app/Theme'
import { useAppMenuContext } from './AppMenuContext'

const styles = {
  root: {
    height: '56px',
    color: CustomPalette.master.white,
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: CustomPalette.master.darkGrey,
    borderRadius: '100px',
    padding: '10px 20px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
  },
  icon: {
    color: CustomPalette.master.white,
  },
  selectedMenu: {
    backgroundColor: CustomPalette.master.selectedGrey,
    color: CustomPalette.master.white,
    borderRadius: '46px',
  },
}

interface MenuProps {
  isSelected?: boolean
}

const UsersMenu = ({ isSelected }: MenuProps) => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.users ?? {}

  const onUsersClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.users, { tenantId: tenant.id })
    }
  }, [tenant])

  const style = isSelected ? styles.selectedMenu : { borderRadius: '46px' }

  return (
    _links?.list && (
      <ListItemButton sx={style} onClick={onUsersClick}>
        <ListItemIcon>
          <PeopleIcon sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary={t('users')} />
      </ListItemButton>
    )
  )
}

const ClientsMenu = ({ isSelected }: MenuProps) => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.clients ?? {}

  const onClientsClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.clients, { tenantId: tenant.id })
    }
  }, [tenant])

  const style = isSelected ? styles.selectedMenu : { borderRadius: '46px' }

  return (
    _links?.list && (
      <ListItemButton sx={style} onClick={onClientsClick}>
        <ListItemIcon>
          <PeopleIcon sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary={t('clients')} />
      </ListItemButton>
    )
  )
}

const AppointmentsMenu = ({ isSelected }: MenuProps) => {
  const { t } = useTranslation()
  const { menu, tenant } = useAppContext()
  const navigate = useAppNavigate()

  const { _links } = menu?.items?.appointments ?? {}

  const onAppointmentsClick = useCallback(() => {
    if (_links?.list && tenant?.id) {
      navigate(AppRoutes.appointments, { tenantId: tenant.id })
    }
  }, [tenant])

  const style = isSelected ? styles.selectedMenu : { borderRadius: '46px' }

  return (
    _links?.list && (
      <ListItemButton sx={style} onClick={onAppointmentsClick}>
        <ListItemIcon>
          <LocalHospitalIcon sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary={t('appointments')} />
      </ListItemButton>
    )
  )
}

const EXCLUDED_ROUTES_IDS = ['tenants', 'tenants_add', 'edit_profile']

const ActionsMenu = () => {
  const { actions } = useAppMenuContext()

  return (
    <Stack direction="row" spacing={2}>
      {actions.map(
        ({ color: actionColor, onClick, icon: ActionIcon }, index) => (
          <Fab
            key={index}
            color="primary"
            onClick={() => onClick?.()}
            sx={{
              background: actionColor,
              ':hover': {
                backgroundColor: actionColor,
                opacity: 0.8,
                color: CustomPalette.master.white,
              },
              width: '36px',
              height: '36px',
            }}
          >
            <ActionIcon
              sx={{
                width: '24px',
                height: '24px',
              }}
            />
          </Fab>
        ),
      )}
    </Stack>
  )
}

export const AppMenu = () => {
  const matches = useMatches()
  const { actions } = useAppMenuContext()

  const selectedMenuId = matches[1]?.id ?? ''

  const excludeRoutes = EXCLUDED_ROUTES_IDS.includes(selectedMenuId)

  if (excludeRoutes && actions.length === 0) {
    return null
  }

  return (
    <Stack sx={styles.root} direction="row" spacing={1}>
      {!excludeRoutes ? (
        <>
          <UsersMenu isSelected={selectedMenuId === 'users'} />
          <ClientsMenu isSelected={selectedMenuId === 'clients'} />
          <AppointmentsMenu isSelected={selectedMenuId === 'appointments'} />
        </>
      ) : null}
      <ActionsMenu />
    </Stack>
  )
}
