import { type UserApi } from '@boommed-suite/contracts'
import { useInjection } from 'inversify-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../domain/services/BoommedService'
import { useAppNavigate } from '../app/AppRouter'
import { useAppContext } from '../app/contexts/AppContext'
import { useAppMenuContext } from '../components/AppMenu/AppMenuContext'
import { TemplatedForm } from '../components/TemplatedForm/TemplatedForm'
import { useService } from '../hooks/useService'

export function UserProfilePage() {
  const { t } = useTranslation()
  const navigate = useAppNavigate()
  const boommedService = useInjection(BoommedService)
  const { menu, setUser } = useAppContext()
  const { setActions } = useAppMenuContext()

  const { data: profile } = useService<UserApi.UserProfileResponse>(
    {
      service: async () => {
        if (menu?.items?.user?._links?.profile) {
          const [response] = await boommedService.fetch(
            menu.items.user._links.profile,
          )

          return response
        }

        return undefined
      },
    },
    [menu],
  )

  const updateProfile = useCallback(
    async (values: UserApi.UpdateUserProfileRequest) => {
      if (profile?._links?.update && menu?.items?.user?._links?.info) {
        await boommedService.fetch(profile._links.update, values)

        const [updatedUser] =
          await boommedService.fetch<UserApi.UserInfoResponse>(
            menu.items.user._links.info,
          )

        setUser(updatedUser)

        navigate(-1)
      }
    },
    [menu, profile],
  )

  useEffect(() => {
    setActions([])
  }, [])

  return profile ? (
    <TemplatedForm
      title={t('profile')}
      fields={profile.templates.update}
      onSubmit={async (values) => {
        await updateProfile(values as UserApi.UpdateUserProfileRequest)
      }}
      submitText={t('edit_profile_submit')}
      initialValues={profile.profile}
    />
  ) : null
}
