import { Strings } from '@boommed-suite/typescript-crossplatform'
import React, { useCallback, useEffect, useState } from 'react'
import { AutocompleteElement } from 'react-hook-form-mui'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '../../hooks/useDebounce'
import { useService } from '../../hooks/useService'

function DefaultRenderOption<T>(
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: T & { label: string },
) {
  return (
    <li key={JSON.stringify(option)} {...optionProps}>
      {option.label}
    </li>
  )
}

export interface AsyncAutoCompleteProps<T> {
  onItemSelected?: (item: T | null) => void
  fetchData: (searchTerm: string | undefined) => Promise<T[]>
  resolveLabel: (item: T) => string
  isEqualValue: (item: T, value?: T) => boolean
  label?: string
  debounceTime?: number
  required?: boolean
  name?: string
  initialSearchTerm?: string
  multiple?: boolean
  renderOption?: (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: T,
  ) => JSX.Element
}

export function AsyncAutoComplete<T>({
  onItemSelected,
  fetchData,
  resolveLabel,
  isEqualValue,
  label = Strings.empty(),
  debounceTime = 300,
  required = false,
  name = Strings.empty(),
  initialSearchTerm,
  multiple = false,
  renderOption,
}: AsyncAutoCompleteProps<T>) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState<string>()
  const [options, setOptions] = useState<(T & { label: string })[]>([])

  const { fetching, data: items } = useService<T[]>(
    {
      service: async () => {
        return await fetchData(searchTerm)
      },
    },
    [searchTerm],
  )

  const debounceOnInputChange = useDebounce((input: string) => {
    setSearchTerm(input)
  }, debounceTime)

  const onInputChange = useCallback((_: unknown, newInputValue: string) => {
    debounceOnInputChange(newInputValue)
  }, [])

  useEffect(() => {
    setSearchTerm(initialSearchTerm)
  }, [initialSearchTerm])

  useEffect(() => {
    if (!fetching && items) {
      setOptions(
        (items ?? []).map((value) => ({
          ...value,
          label: resolveLabel(value),
        })),
      )
    }
  }, [fetching, items])

  return (
    <AutocompleteElement
      label={label}
      loading={fetching}
      multiple={multiple}
      name={name}
      options={options}
      required={required}
      autocompleteProps={{
        loadingText: t('loading'),
        noOptionsText: Strings.empty(),
        isOptionEqualToValue: isEqualValue,
        onInputChange,
        onChange: (_, value) => {
          onItemSelected?.(value as T)
        },
        renderOption: (props, option) => {
          delete (props as { key: unknown }).key

          if (renderOption) {
            return renderOption(props, option)
          }

          return DefaultRenderOption(props, option)
        },
      }}
    />
  )
}
