import { type SuiteApi } from '@boommed-suite/contracts'
import { mdiCat, mdiDog, mdiPawOff } from '@mdi/js'
import MDIIcon from '@mdi/react'
import AddIcon from '@mui/icons-material/Add'
import { Avatar, Box, Chip, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useInjection } from 'inversify-react'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatches } from 'react-router-dom'
import { BoommedService } from '../../../domain/services/BoommedService'
import {
  AppRoutes,
  buildNavigationPath,
  useAppNavigate,
} from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { GradientPaletteOptions, theme } from '../../app/Theme'
import {
  AppMenuAction,
  useAppMenuContext,
} from '../../components/AppMenu/AppMenuContext'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useService } from '../../hooks/useService'
import { useTenantDeepLink } from '../../hooks/useTenantDeepLink'
import { styles } from './AppointmentsPage.styles'
import { NewAppointment } from './components/NewAppointment'

const SPECIES_ICON_MAP: Record<string, ReactElement> = {
  cat: <MDIIcon path={mdiCat} size={1} />,
  dog: <MDIIcon path={mdiDog} size={1} />,
}

const SpeciesIcon = ({ species }: { species: string }) => {
  return SPECIES_ICON_MAP[species] ?? <MDIIcon path={mdiPawOff} size={1} />
}

const truncateFullName = (fullName: string) => {
  const [firstName, secondName] = fullName.split(' ')

  const truncatedSecondName = secondName?.length > 0 ? `${secondName[0]}.` : ''

  return `${firstName} ${truncatedSecondName}`.trim()
}

export function AppointmentsPage() {
  const boommedService = useInjection(BoommedService)
  const { t } = useTranslation()
  const { formatDate } = useDateFormatter()
  const { menu, tenant } = useAppContext()
  const { setActions } = useAppMenuContext()
  const navigate = useAppNavigate()
  const matches = useMatches()

  useTenantDeepLink()

  const { fetching, data: appointments } =
    useService<SuiteApi.AppointmentsListResponse>(
      {
        service: async () => {
          const listLink = menu?.items?.appointments?._links?.list

          if (listLink !== undefined) {
            const [pagedAppointments] =
              await boommedService.fetch<SuiteApi.AppointmentsListResponse>(
                listLink,
              )

            return pagedAppointments
          }

          return undefined
        },
      },
      [],
    )

  const pageActions = useMemo(() => {
    const actions: AppMenuAction[] = []

    const displayNewButton = appointments?._links.new

    if (displayNewButton && tenant?.id) {
      actions.push({
        title: t('add'),
        icon: AddIcon,
        color: (theme.palette.primary as GradientPaletteOptions).gradient,
        onClick: () => {
          if (matches[1]?.id === 'appointments') {
            const link = buildNavigationPath(AppRoutes.appointmentAdd, {
              tenantId: tenant.id,
              action: 'add',
            })
            navigate(link)
          }
        },
      })
    }
    return actions
  }, [appointments, tenant, matches])

  useEffect(() => {
    setActions(pageActions)
  }, [pageActions])

  return (
    <>
      <Stack direction={'column'} height="100%">
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <DataGrid
            loading={fetching}
            sx={styles.root}
            columnBufferPx={5}
            rows={appointments?.page?.elements ?? []}
            columns={[
              {
                field: 'clientName',
                headerName: t('appointments_client_name'),
                align: 'left',
                headerAlign: 'left',
                flex: 1,
                renderCell: (params) => <strong>{params.value}</strong>,
              },
              {
                field: 'petName',
                headerName: t('appointments_pet_name'),
                flex: 1,
              },
              {
                field: 'species',
                headerName: t('appointments_species'),
                flex: 1,
                renderCell: (params) => {
                  const species = params.value as string

                  return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <SpeciesIcon species={species} />
                      <span style={{ textTransform: 'capitalize' }}>
                        {params.value}
                      </span>
                    </Stack>
                  )
                },
              },
              {
                field: 'diagnosticArea',
                headerName: t('appointments_diagnostic_area'),
                flex: 1,
                renderCell: (params) => {
                  const label = params.value as string

                  return (
                    <Chip
                      style={{ textTransform: 'capitalize' }}
                      label={label}
                    />
                  )
                },
              },
              {
                field: 'veterinaryName',
                headerName: t('appointments_veterinary_name'),
                flex: 1,
                renderCell: (params) => {
                  const avatar = (params.value as ReactElement[])[0]

                  return (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar sx={styles.veterinaryAvatar}>{avatar}</Avatar>
                      <span>{truncateFullName(params.value)}</span>
                    </Stack>
                  )
                },
              },
              {
                field: 'date',
                headerName: t('appointments_date'),
                flex: 1,
                valueGetter: (params) => formatDate(params),
              },
              {
                field: 'comment',
                headerName: t('appointments_comment'),
                flex: 1,
              },
            ]}
            checkboxSelection
            hideFooterPagination
          />
        </Box>
      </Stack>
      <NewAppointment />
    </>
  )
}
