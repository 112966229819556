import { SxProps, Theme } from '@mui/material'
import React, { useContext } from 'react'

export interface AppMenuAction {
  title: string
  icon: React.ComponentType<{ sx?: SxProps<Theme> }>
  color: string
  onClick?: () => void
}

interface AppMenuStore {
  actions: AppMenuAction[]
  setActions: (actions: AppMenuAction[]) => void
}

const AppMenuContext = React.createContext<AppMenuStore>({
  actions: [],
  setActions: () => undefined,
})

export const AppMenuContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [actions, setActions] = React.useState<AppMenuAction[]>([])

  return (
    <AppMenuContext.Provider value={{ setActions, actions }}>
      {children}
    </AppMenuContext.Provider>
  )
}

export const useAppMenuContext = () => useContext(AppMenuContext)
