import { useInjection } from 'inversify-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../domain/services/BoommedService'
import { useAppNavigate } from '../app/AppRouter'
import { useAppContext } from '../app/contexts/AppContext'
import { useAppMenuContext } from '../components/AppMenu/AppMenuContext'
import { TemplatedForm } from '../components/TemplatedForm/TemplatedForm'

export function CreateTenantPage() {
  const { setActions } = useAppMenuContext()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const navigate = useAppNavigate()
  const { t } = useTranslation()
  const { menu } = useAppContext()
  const boommedService = useInjection(BoommedService)

  const createTenant = useCallback(async (values: unknown) => {
    if (menu?.items?.tenants?._links?.new) {
      const [_, error] = await boommedService.fetch(
        menu.items.tenants._links.new,
        values,
      )

      if (error) {
        setUnexpectedError(error)
        return
      }

      navigate(-1)
    }
  }, [])

  useEffect(() => {
    setActions([])
  }, [])

  return (
    <TemplatedForm
      title={t('tenant_info')}
      fields={menu?.items?.tenants?.data?.templates?.new}
      onSubmit={createTenant}
      submitText={t('tenant_submit')}
    />
  )
}
