import { type LoggerService } from '@boommed-suite/typescript-crossplatform'
import { CssBaseline } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import React from 'react'
import { ConsoleLogger } from '../../domain/services/ConsoleLogger'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { LoginPage } from '../pages/LoginPage/LoginPage'
import { SplashPage } from '../pages/SplashPage'
import { AppRouter } from './AppRouter'
import { AppBindings } from './configuration/AppBindings'
import { configuration } from './configuration/Configuration'
import { AppContextProvider, useAppContext } from './contexts/AppContext'
import {
  type BindingType,
  IoCContextProvider,
} from './contexts/IoCContextProvider'
import { NotificationContextProvider } from './contexts/NotificationContext'

import { Theme } from './Theme'

const AppPage = () => {
  const { menu } = useAppContext()

  if (!menu) {
    return <SplashPage />
  }

  return menu.signedIn ? <AppRouter /> : <LoginPage />
}

interface AppProps {
  bindings?: readonly BindingType<unknown>[]
  logger?: LoggerService
}

export const App = ({
  bindings = AppBindings,
  logger = new ConsoleLogger(),
}: AppProps) => (
  <IoCContextProvider bindings={bindings} logger={logger}>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={configuration.google.clientId}>
        <Theme>
          <CssBaseline enableColorScheme />
          <NotificationContextProvider>
            <AppContextProvider>
              <AppPage />
            </AppContextProvider>
          </NotificationContextProvider>
        </Theme>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </IoCContextProvider>
)
