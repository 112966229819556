import { Grid2, IconButton, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useMatches } from 'react-router-dom'
import { AppRoutes, useAppNavigate } from '../../app/AppRouter'
import i18n from '../../app/i18n/i18n'
import { BoommedSuiteSmallLogoColor } from '../../assets/BoommedSuiteSmallLogoColor'
import { UserProfile } from '../UserProfile/UserProfile'
import { styles } from './AppHeader.styles'

export const AppHeader = () => {
  const matches = useMatches()
  const navigate = useAppNavigate()

  const onMyTenantsClick = useCallback(() => {
    navigate(AppRoutes.tenants)
  }, [])

  const title = matches?.length === 2 ? i18n.t(matches[1].id) : undefined

  return (
    <Grid2 sx={styles.root} container>
      <Grid2 sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid2>
          <IconButton onClick={onMyTenantsClick}>
            <BoommedSuiteSmallLogoColor />
          </IconButton>
        </Grid2>
        <Grid2 alignContent="center">
          <Typography variant="h4">{title}</Typography>
        </Grid2>
      </Grid2>
      <Grid2>
        <UserProfile />
      </Grid2>
    </Grid2>
  )
}
