import { type PassportApi, type SuiteApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import { useAppContext } from '../../app/contexts/AppContext'
import {
  AsyncAutoComplete,
  type AsyncAutoCompleteProps,
} from '../AsyncAutoComplete/AsyncAutoComplete'
import { ClientSearchField } from '../ClientSearchField/ClientSearchField'

type PatientSearchFieldProps = Pick<
AsyncAutoCompleteProps<PassportApi.UserPetDetailResponse>,
'onItemSelected' | 'required'
> & { field?: string }

export const PatientSearchField = ({
  onItemSelected,
  required,
  field = 'patient',
}: PatientSearchFieldProps) => {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()
  const [selectedClient, setSelectedClient] =
    useState<SuiteApi.ClientDetailResponse | null>(null)
  const [initialSearchTerm, setInitialSearchTerm] = useState<string>()

  const fetchData = useCallback(
    async (
      searchTerm: string | undefined,
    ): Promise<PassportApi.UserPetDetailResponse[]> => {
      if (!selectedClient) {
        return []
      }

      const listLink = menu?.items?.pets._links?.list

      if (listLink !== undefined) {
        const [pagedClients] =
          await boommedService.fetch<PassportApi.ListUserPetsResponse>(
            listLink,
            undefined,
            {
              searchTerm: searchTerm ?? Strings.empty(),
              userId: selectedClient.userId,
            },
          )

        return pagedClients?.page.elements ?? []
      }

      return []
    },
    [selectedClient],
  )

  const onClientSelected = useCallback(
    (client: SuiteApi.ClientDetailResponse | null) => {
      setSelectedClient(client)
      setInitialSearchTerm(
        initialSearchTerm === undefined ? Strings.empty() : undefined,
      )
    },
    [initialSearchTerm],
  )

  return (
    <>
      <ClientSearchField
        required={required}
        onItemSelected={onClientSelected}
      />
      <AsyncAutoComplete
        initialSearchTerm={initialSearchTerm}
        fetchData={fetchData}
        resolveLabel={(item) => item.name}
        isEqualValue={(item, value) =>
          item._links.self.href === value?._links.self.href
        }
        onItemSelected={onItemSelected}
        label={t('patient')}
        required={required}
        name={field}
      />
    </>
  )
}
