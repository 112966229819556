import { type UserApi } from '@boommed-suite/contracts'
import { Strings } from '@boommed-suite/typescript-crossplatform'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, Stack, Typography } from '@mui/material'
import { DataGrid, GridFilterModel, GridSortModel, type GridRowSelectionModel } from '@mui/x-data-grid'
import { useInjection } from 'inversify-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../domain/services/BoommedService'
import {
  AppRoutes,
  buildNavigationPath,
  useAppNavigate,
} from '../../app/AppRouter'
import { CustomPalette, GradientPaletteOptions, theme } from '../../app/Theme'
import { useAppContext } from '../../app/contexts/AppContext'
import {
  AppMenuAction,
  useAppMenuContext,
} from '../../components/AppMenu/AppMenuContext'
import { TemplatedForm } from '../../components/TemplatedForm/TemplatedForm'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useService } from '../../hooks/useService'
import { useTenantDeepLink } from '../../hooks/useTenantDeepLink'
import { styles } from './UsersPage.styles'
import { EditUserDialog } from './components/EditUserDialog'

export function UsersPage() {
  const boommedService = useInjection(BoommedService)
  const { menu, tenant } = useAppContext()
  const { t } = useTranslation()
  const { formatDate } = useDateFormatter()
  const { setActions } = useAppMenuContext()
  const navigate = useAppNavigate()

  const [selectedUsers, setSelectedUsers] = useState<
    UserApi.UserDetailResponse[]
  >([])
  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }
  const [refreshUsers, setRefreshUsers] = useState<boolean>(true)
  const [editUser, setEditUser] = useState<UserApi.UserDetailResponse>()
  const [users, setUsers] = useState<UserApi.UsersListResponse>()
  const [sortField, setSortField] = useState<string>(Strings.empty())
  const [sortOrder, setSortOrder] = useState<string>(Strings.empty())
  const [filterModel, setFilterModel] = useState<GridFilterModel>()

  useTenantDeepLink()

  const { fetching } = useService<UserApi.UsersListResponse>(
    {
      service: async () => {
        if (menu?.items?.users?._links?.list && refreshUsers) {
          setSelectedUsers([])
          setRefreshUsers(false)

          const uriParameters: Record<string, string> = {
            sortField,
            sortOrder,
          }

          if (filterModel?.items.length) {
            uriParameters.filterField = filterModel.items[0].field
            uriParameters.filterOperator = filterModel.items[0].operator
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            uriParameters.filterValue = filterModel.items[0].value
          }

          const [pagedUsers, error] =
            await boommedService.fetch<UserApi.UsersListResponse>(
              menu.items.users._links.list,
              undefined,
              uriParameters
            )

          if (error) {
            throw error
          }

          setUsers(pagedUsers)
        }
      },
    },
    [menu, refreshUsers],
  )

  const deleteSelectedUsers = useCallback(async () => {
    if (users) {
      const [_, error] = await boommedService.fetch(users._links.delete, {
        ids: selectedUsers.map((user) => user.id),
      })

      if (error) {
        setUnexpectedError(error)
        return
      }

      setRefreshUsers(true)
    }
  }, [selectedUsers])

  const pageActions = useMemo(() => {
    const actions: AppMenuAction[] = []

    if (menu?.items?.users?._links?.new && tenant?.id) {
      actions.push({
        title: t('add'),
        icon: AddIcon,
        color: (theme.palette.primary as GradientPaletteOptions).gradient,
        onClick: () => {
          const link = buildNavigationPath(AppRoutes.userAdd, {
            tenantId: tenant?.id,
          })
          navigate(link)
        },
      })
    }

    const displayDeleteButton =
      selectedUsers.length &&
      selectedUsers.reduce(
        (display, user) => display && Boolean(user._links.delete),
        true,
      )

    if (displayDeleteButton) {
      actions.push({
        title: t('delete'),
        icon: DeleteIcon,
        color: CustomPalette.master.red,
        onClick: deleteSelectedUsers,
      })
    }

    return actions
  }, [menu, selectedUsers, tenant])

  useEffect(() => {
    setActions(pageActions)
  }, [pageActions])

  const onSelectionChange = useCallback(
    (selectedItems: GridRowSelectionModel) => {
      const filteredUsers = users?.page.elements.filter((user) =>
        selectedItems.includes(user.id),
      )
      setSelectedUsers(filteredUsers ?? [])
    },
    [users],
  )

  const onEditUser = useCallback((user: UserApi.UserDetailResponse) => {
    setEditUser(user)
  }, [])

  const onFinishEditUser = useCallback((submitted: boolean) => {
    setEditUser(undefined)
    if (submitted) {
      setRefreshUsers(true)
    }
  }, [])

  const onSortChange =  useCallback(
    (sortModel: GridSortModel) => {
      setSortField(sortModel[0]?.field ?? Strings.empty())
      setSortOrder(sortModel[0]?.sort ?? Strings.empty())
      setRefreshUsers(true)
    },
    [sortField, sortOrder],
  )

  const onFilterChange = useCallback(
    (filterModel: GridFilterModel) => {
      const filters = filterModel.items.length ? filterModel : undefined
      setFilterModel(filters)
      setRefreshUsers(true)
    },
    [filterModel],
  )

  const RoleDropdownFilter = () => {
    const roleFields = menu?.items?.users?.data?.templates?.new
    Object.keys(roleFields!).forEach(key => { if(key !== 'roles'){delete roleFields![key]}})
    roleFields!.roles.values?.forEach(role => {role.description = ''})
   
    return (
      <TemplatedForm<{
        roles: { id: string }[]
      }>
        fields={roleFields}
        onSubmit={ (values) => {
          const filters: GridFilterModel = {items:[{field:'roles', operator:'isAnyOf', value:values.roles.map(role => role.id).join(', ')}]}
          onFilterChange(filters)
        }}
        submitText={t('submit')}
        title=''
        displayCancelButton={false}
        sx={{
          marginTop:-10,
          paddingBottom:0,
          pl:1,
          pr:0
        }}
      />
    )
  }
  return (
    <>
      <Stack direction={'column'} height="100%">
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <DataGrid
            loading={fetching}
            sx={styles.root}
            columnBufferPx={3}
            rows={users?.page.elements ?? []}
            onRowSelectionModelChange={onSelectionChange}
            sortingMode="server"
            onSortModelChange={onSortChange}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            filterDebounceMs={1000}
            columns={[
              {
                field: 'email',
                headerName: t('users_email_header'),
                align: 'left',
                headerAlign: 'left',
                flex: 1,
                renderCell: (params) => (
                  <Stack direction="column" sx={styles.userNameCellContainer}>
                    {params.row.name && (
                      <Typography noWrap variant="h5">
                        {params.row.name}
                      </Typography>
                    )}
                    {params.row.email && (
                      <Typography
                        noWrap
                        variant={params.row.name ? 'h6' : 'h5'}
                      >
                        {params.row.email}
                      </Typography>
                    )}
                  </Stack>
                ),
              },
              {
                field: 'roles',
                headerName: t('users_roles_header'),
                sortable: false,
                flex: 1,
                filterOperators: [
                  {
                    label: t('is_any_of'),
                    value: 'isAnyOf',
                    InputComponent: RoleDropdownFilter,
                    getApplyFilterFn: (filterItem) => {
                      if (!filterItem.value) {
                        return null
                      }
                      return (params) => params.value === filterItem.value;
                    },
                  },
                ],
                renderCell: (params) => (
                  <Button
                    variant="text"
                    sx={styles.rolesButton}
                    onClick={() => {
                      onEditUser(params.row)
                    }}
                  >
                    <Typography noWrap variant="h5">
                      {params.row.roles.map((role) => t(role)).join(', ')}
                    </Typography>
                  </Button>
                ),
              },
              {
                field: 'createdAt',
                headerName: t('users_created_at_header'),
                align: 'left',
                headerAlign: 'left',
                flex: 1,
                valueGetter: (params) => formatDate(params),
              },
            ]}
            checkboxSelection
            pageSizeOptions={[users?.page.pageSize ?? 0]}
            hideFooterPagination
            slots={{
              footer: () => (
                <Typography variant="h6" sx={styles.footer}>
                  {t('total_records', {
                    total: users?.page.elements.length ?? 0,
                  })}
                </Typography>
              ),
            }}
          />
        </Box>
      </Stack>
      {editUser && users && (
        <EditUserDialog
          user={editUser}
          editTemplate={users.templates.edit}
          onClose={onFinishEditUser}
        />
      )}
    </>
  )
}
