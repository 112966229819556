import { type UserApi } from '@boommed-suite/contracts'
import {
  HttpRequestError,
  NetworkError,
  isError,
} from '@boommed-suite/typescript-crossplatform'
import { Dialog } from '@mui/material'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../../../domain/services/BoommedService'
import { Crashlytics } from '../../../../domain/services/Crashlytics'
import { useNotificationContext } from '../../../app/contexts/NotificationContext'
import { TemplatedForm } from '../../../components/TemplatedForm/TemplatedForm'
import { styles } from './EditUserDialog.styles'

interface SubmitValues {
  roles: { id: string }[]
}

interface EditUserDialogProps {
  user: UserApi.UserDetailResponse
  editTemplate: Record<
  string,
  UserApi.TemplateField<UserApi.RoleDetailResponse>
  >
  onClose?: (submitted: boolean) => void
}

export const EditUserDialog = ({
  user,
  onClose,
  editTemplate,
}: EditUserDialogProps) => {
  const { t } = useTranslation()
  const boommedService = useInjection(BoommedService)
  const crashlytics = useInjection(Crashlytics)
  const { openNotification } = useNotificationContext()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const updateUser = useCallback(async ({ roles }: SubmitValues) => {
    const [result, error] = await boommedService.fetch(user._links.update, {
      roles: roles.map(({ id }) => id),
    })

    if (isError(NetworkError)(error)) {
      setUnexpectedError(error)
      return
    }

    if (isError(HttpRequestError)(error) || isError(Error)(error)) {
      openNotification({
        severity: 'error',
        text: t('failed_to_edit_user'),
      })
      crashlytics.error(error, { user })
      return
    }

    if (result) {
      onClose?.(true)
    }
  }, [])

  return (
    <Dialog
      onClose={() => {
        onClose?.(false)
      }}
      open={true}
    >
      <TemplatedForm<SubmitValues>
        sx={styles.form}
        title={user.name ?? user.email}
        initialValues={{ roles: user.roles }}
        fields={editTemplate}
        onSubmit={async (values) => {
          await updateUser(values)
        }}
        submitText={t('save')}
        onCancel={() => {
          onClose?.(false)
        }}
      />
    </Dialog>
  )
}
