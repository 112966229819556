import { useMemo } from 'react'

export const debounce = <T extends unknown[]>(
  action: (...args: T) => void,
  delay: number
) => {
  let timeoutId = 0

  return (...args: T) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      action(...args)
    }, delay) as unknown as number
  }
}

export const useDebounce = <T extends unknown[]>(
  action: (...args: T) => void,
  delay: number
) => {
  return useMemo(() => debounce(action, delay), [])
}
