import { HttpRequest } from '@boommed-suite/typescript-crossplatform'
import { BoommedService } from '../../../domain/services/BoommedService'
import { Crashlytics } from '../../../domain/services/Crashlytics'
import { type BindingType } from '../contexts/IoCContextProvider'

export const AppBindings: readonly BindingType<unknown>[] = [
  {
    type: BoommedService,
    instance: new BoommedService(new HttpRequest())
  },
  {
    type: Crashlytics
  }
]
