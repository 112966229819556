import {
  HttpRequestError,
  NetworkError,
  StatusCode,
  isError,
} from '@boommed-suite/typescript-crossplatform'
import { useInjection } from 'inversify-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BoommedService } from '../../domain/services/BoommedService'
import { Crashlytics } from '../../domain/services/Crashlytics'
import { useAppNavigate } from '../app/AppRouter'
import { useAppContext } from '../app/contexts/AppContext'
import { useNotificationContext } from '../app/contexts/NotificationContext'
import { useAppMenuContext } from '../components/AppMenu/AppMenuContext'
import { TemplatedForm } from '../components/TemplatedForm/TemplatedForm'

interface SubmitValues {
  email: string
  roles: { id: string }[]
}

export function AddUserPage() {
  const navigate = useAppNavigate()
  const { openNotification } = useNotificationContext()
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()
  const { t } = useTranslation()
  const crashlytics = useInjection(Crashlytics)
  const { setActions } = useAppMenuContext()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const addUser = useCallback(async ({ email, roles }: SubmitValues) => {
    if (menu?.items?.users?._links?.new) {
      const [result, error] = await boommedService.fetch(
        menu.items.users._links.new,
        {
          email,
          roles: roles.map(({ id }) => id),
        },
      )

      if (
        isError(HttpRequestError)(error) &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        error.statusCode === StatusCode.BAD_REQUEST
      ) {
        openNotification({
          severity: 'error',
          text: t('user_duplicate_error'),
        })
        return
      }

      if (isError(NetworkError)(error)) {
        setUnexpectedError(error)
        return
      }

      if (isError(Error)(error) || isError(HttpRequestError)(error)) {
        crashlytics.error(error)
        openNotification({
          severity: 'error',
          text: t('user_add_error'),
        })
        return
      }

      if (result) {
        navigate(-1)
      }
    }
  }, [])

  useEffect(() => {
    setActions([])
  }, [])

  return (
    <TemplatedForm<SubmitValues>
      fields={menu?.items?.users?.data?.templates?.new}
      onSubmit={async (values) => {
        await addUser(values)
      }}
      submitText={t('users_add')}
    />
  )
}
