import { type LoggerService } from '@boommed-suite/typescript-crossplatform'
import { Container } from 'inversify'
import { Provider, useInjection } from 'inversify-react'
import React, { type ReactNode } from 'react'

export const LoggerSymbol = Symbol('LoggerService')

export interface BindingType<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: new (...args: any[]) => T
  instance?: T
}

interface IoCContextProviderProps {
  children: ReactNode
  bindings: readonly BindingType<unknown>[]
  logger: LoggerService
}

function registerBinding<T> ({
  type,
  instance,
  container
}: {
  type: new (...args: unknown[]) => T
  instance?: T
  container: Container
}) {
  if (instance) {
    container.bind<T>(type).toConstantValue(instance)
  } else {
    container.bind<T>(type).toSelf()
  }
}

export const IoCContextProvider = ({
  children,
  bindings,
  logger
}: IoCContextProviderProps) => (
  <Provider
    container={() => {
      const container = new Container()

      container.bind<LoggerService>(LoggerSymbol).toConstantValue(logger)

      bindings.forEach(({ type, instance }) => {
        registerBinding({ type, instance, container })
      })

      return container
    }}
  >
    {children}
  </Provider>
)

export const useLogger = () => useInjection<LoggerService>(LoggerSymbol)
