import { resolve } from 'inversify-react'
import React, { Component, type ReactNode } from 'react'
import { Crashlytics } from '../../domain/services/Crashlytics'
import { UnexpectedErrorPage } from '../pages/UnexpectedErrorPage'

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}
interface ErrorBoundaryProps {
  children?: ReactNode
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  private readonly crashlytics!: Crashlytics

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }

    resolve(Crashlytics)(this, 'crashlytics')

    window.addEventListener(
      'unhandledrejection',
      this.unhandledRejectionHandler,
    )
  }

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.crashlytics.error(error, errorInfo)
  }

  public componentWillUnmount(): void {
    window.removeEventListener(
      'unhandledrejection',
      this.unhandledRejectionHandler,
    )
  }

  private readonly unhandledRejectionHandler = (
    event: PromiseRejectionEvent,
  ) => {
    this.crashlytics.error(event.reason)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.setState({ hasError: true, error: event.reason })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <UnexpectedErrorPage>{this.state.error?.message}</UnexpectedErrorPage>
      )
    }

    return this.props.children
  }
}
